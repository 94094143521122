<style scoped lang="scss">
    .page-daywork-configs {
        .c-datetime-range {
            min-width: 360px;
        }
    }
</style>

<template>
    <div class="page-daywork-configs">
        <div class="toolbars mrgb5">
            <div class="buttons mrgb5">
                <div class="fl">
                    <el-button class="mrgr5 mrgb5" type="primary" size="medium" @click="_edit()"><i class="fa fa-edit"></i> 新增配置</el-button>
                </div>
                <div class="fr">
                    <el-date-picker class="c-datetime-range mrgr5 mrgb5" v-model="selectedTime" type="datetimerange" size="medium"
                        :picker-options="pickerOptions" :default-time="['00:00:00', '23:59:59']"
                        range-separator="至"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                        align="right">
                    </el-date-picker>
                    <el-button type="primary" size="medium" @click="getDataList()"><i class="fa fa-search"></i> 查询</el-button>
                    <el-button class="mrgr5 mrgb5" type="warning" size="medium" @click="_updateState()"><i class="el-icon-refresh"></i> 更新工作状态</el-button>
                </div>
                <div class="clearb"></div>
            </div>
        </div>
        <el-table :data="tableData" style="width: 100%" border size="medium">
            <el-table-column label="名称" prop="name"></el-table-column>
            <el-table-column label="联系电话" prop="tel"></el-table-column>
            <el-table-column label="开始时间" prop="startTime"></el-table-column>
            <el-table-column label="结束时间" prop="endTime"></el-table-column>
            <el-table-column label="操作" align="center" width="160" fixed="right">
                <template slot-scope="scope">
                    <el-button type="primary" plain size="mini" @click="_edit(scope.row)">编辑</el-button>
                    <el-button type="danger" plain size="mini" @click="_delete(scope.$index)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>

        <!--编辑工作配置-->
        <edit :ref="refEdit" @refreshData="saveConfigs"></edit>
    </div>
</template>

<script>
import * as funMaintenance from '@/api/maintenance'
import edit from "./components/editWork"
import moment from "moment"
export default {
    name: "pageDayWorkConfigs",
    components: { edit },
    data() {
        let that = this
        return {
            refEdit: "refDayWorkConfigsToEditWork",
            listQuery: {
                area: ""
            },
            tableData: [],
            selectedTime: [],
            pickerOptions: {
                shortcuts: [
                    {
                        text: "昨天",
                        onClick: (picker) => {
                            let timeArr = that.setPickerTime(picker, 1, "days")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一周",
                        onClick: (picker) => {
                            let timeArr = that.setPickerTime(picker, 1, "weeks")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一月",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 1, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近三月",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 3, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近半年",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 6, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一年",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 1, "years")
                            that.setQueryTime(timeArr)
                        }
                    }
                ]
            }
        }
    },
    watch: {
        "$store.getters.serviceArea"(v) {
            this.mxIsSameActiveTabWithRoute(() => {
                this.getDataList()
            })
        }
    },
    mounted() {
        this.getDataList()
    },
    methods: {
        setQueryTime(timeArr) {
            this.selectedTime = [timeArr[0] || "", timeArr[1] || ""]
        },
        getDataList() {
            window.$common.fullLoading()
            this.listQuery.area = this.$store.getters.serviceArea || ""
            funMaintenance.GetEmployeeDayWorkConfigs(this.listQuery).then(response => {
                window.$common.closeFullLoading()
                if (response && response.length > 0) {
                    this.tableData = response
                } else {
                    this.tableData = []
                }
            })
        },
        _updateState() {
            let psTime = ""
            let peTime = ""
            if (this.selectedTime && this.selectedTime[0] && moment(this.selectedTime[0]).isValid()) {
                psTime = moment(this.selectedTime[0]).format("YYYY-MM-DD HH:mm:ss")
            }
            if (this.selectedTime && this.selectedTime[1] && moment(this.selectedTime[1]).isValid()) {
                peTime = moment(this.selectedTime[1]).format("YYYY-MM-DD HH:mm:ss")
            }
            let params = {
                area: this.listQuery.area,
                startDate: psTime,
                endDate: peTime
            }
            if (!params.startDate || !params.endDate) {
                this.warningMsg("请选择更新时间段!")
                return false
            }
            window.$common.fullLoading()
            funMaintenance.HandleDayWork(params).then(() => {
                window.$common.closeFullLoading()
                this.successMsg("工作状态更新成功!")
            })
        },
        _edit(row) {
            this.$refs[this.refEdit].open(row)
        },
        saveConfigs(row) {
            let datas = JSON.parse(JSON.stringify(this.tableData))
            let index = datas.findIndex(x => x.tel === row.tel && x.name === row.name)
            if (index !== -1) {
                datas.splice(index, 1, row)
            } else {
                datas.push(row)
            }
            let params = {
                area: this.listQuery.area,
                data: datas
            }
            funMaintenance.SetEmployeeDayWorkConfigs(params).then(res => {
                this.getDataList()
                this.$refs[this.refEdit].commonSuccess()
            })
        },
        _delete(index) {
            this.confirm("确定要删除当前员工的工作时间配置吗?").then(() => {
                this.tableData.splice(index, 1)
                let params = {
                    area: this.listQuery.area,
                    data: this.tableData
                }
                funMaintenance.SetEmployeeDayWorkConfigs(params).then(res => {
                    this.getDataList()
                    this.successMsg("操作成功!")
                })
            })
        }
    }
}
</script>